import React from "react";
import Contents from "./components/Contents";

function App() {

  return (
    <React.Fragment>
      <Contents />
    </React.Fragment>
  );
}

export default App;
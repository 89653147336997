import React, { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/esm/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import CardGroup from "react-bootstrap/CardGroup";

const Temperatures = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sensorData, setSensorData] = useState([]);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          getSensorValues(result.things);
          //console.log(result.things);
        },

        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const getSensorValues = (things) => {
    let sensorItems = [];
    var validity;
    var currentDate = new Date();

    for (var thing = 0; thing < things.length; thing++) {
      // Get thing datetime
      var date = new Date(things[thing].datetime);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      for (var i = 0; i < things[thing].sensor_values.length; i++) {
        let sensorItem = {};
        // date coloe is read value is older that 1 hour (in milliseconds).
        if (currentDate.getTime() - date.getTime() > 3600000) {
          validity = false;
        } else {
          validity = true;
        }
        //console.log(things[thing].sensor_values[i].name, things[thing].sensor_values[i].temperature, date.toLocaleString(undefined, options), valid);
        sensorItem["name"] = things[thing].sensor_values[i].name;
        sensorItem["temperature"] = things[thing].sensor_values[i].temperature;
        sensorItem["datetime"] = date.toLocaleString(undefined, options);
        sensorItem["valid"] = validity;

        sensorItems.push(sensorItem);
        console.log(sensorItems);
      }
    }
    setSensorData(sensorItems);
  };

  if (error) {
    return (
      <Stack>
        <Card className="text-center bg-warning text-danger">
          <Card.Title>
            <h3>Lämpötilat</h3>
          </Card.Title>
          <h5>
            <Card.Body>Error: {error.message}</Card.Body>
          </h5>
        </Card>
      </Stack>
    );
  } else if (!isLoaded) {
    return (
      <Stack>
        <Button variant="secondary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Ladataan...
        </Button>
      </Stack>
    );
  } else if (0) {
    return (
      <>
        <Container className="mx-auto text-center bg-secondary">
          <Row className="mx-auto">
            <Col>1 of 1</Col>
          </Row>
          <Row>
            <Col>1 of 1</Col>
          </Row>
        </Container>
        <Card className="mx-auto text-center bg-secondary">
          <Card.Title>
            <h3>Lämpötilat</h3>
          </Card.Title>
          <Card.Body className="mx-auto">{}</Card.Body>
        </Card>
      </>
    );
  } else {
    return (
      <Card className="bg-secondary">
        <Card className="text-center bg-secondary mx-auto" border="secondary">
          <Card.Title>
            <h3>Lämpötilat</h3>
          </Card.Title>
          <Card.Body>
            <Row direction="horizontal" gap={1}>
              {sensorData.map((sensor) => (
                <Col key={sensor.name}>
                  <ListGroup key={sensor.name}>
                    {sensor.valid == true ? (
                      <>
                        <ListGroup.Item
                          className="bg-primary text-white"
                          key={sensor.name}
                        >
                          <h4>{sensor.name}</h4>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="bg-primary text-white"
                          key={sensor.name + "temperature"}
                        >
                          <h2>{sensor.temperature}</h2>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="bg-primary text-muted"
                          key={sensor.name + "datetime"}
                        >
                          {sensor.datetime}{" "}
                        </ListGroup.Item>
                      </>
                    ) : (
                      <>
                        <ListGroup.Item
                          className="bg-warning text-danger"
                          key={sensor.name}
                        >
                          {sensor.name}
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="bg-warning text-danger"
                          key={sensor.name + "temperature"}
                        >
                          <h2>{sensor.temperature}</h2>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="bg-warning text-danger"
                          key={sensor.name + "datetime"}
                        >
                          {" "}
                          {sensor.datetime}
                        </ListGroup.Item>
                      </>
                    )}
                  </ListGroup>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Card>
    );
  }
};

export default Temperatures;

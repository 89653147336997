import React from "react";
import Category from "./Category";
import Temperatures from "./Temperatures";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Contents = (props) => {
  var links = {
    Kaupat: [
      {
        text: "S-Market Kuhmoinen",
        URL: "https://www.s-kanava.fi/toimipaikka/s-market-kuhmoinen/511774994",
      },
      {
        text: "K-Market Kippari",
        URL: "https://www.k-ruoka.fi/kauppa/k-market-kippari",
      },
      { text: "Leipomo Hanki", URL: "http://www.leipomohanki.fi/" },
      { text: "Alko", URL: "https://www.alko.fi/myymalat-palvelut/2499/" },
      {
        text: "Kalayhtymä Kääpä ",
        URL: "https://fi-fi.facebook.com/pages/Kalayhtym%C3%A4-K%C3%A4%C3%A4p%C3%A4/226266627386228",
      },
      { text: "Neste Kuhmoinen", URL: "https://www.nestekuhmoinen.fi/" },
      {
        text: "Kuhmoisten palvelut",
        URL: "https://www.visitkuhmoinen.fi/palvelut/",
      },
    ],
    Terveydenhoito: [
      {
        text: "Terveyspalvelut",
        URL: "https://www.kuhmoinen.fi/content/fi/1/311258/Sosiaali-%20ja%20terveyspalvelut.html",
      },
      { text: "Apteekki", URL: "https://www.kuhmoistenapteekki.fi/" },
    ],
    "Kahvilat/Ravintolat": [
      { text: "Ravintola Sahanranta", URL: "https://sahanranta.fi/" },
      {
        text: "Sarin kotikahvila",
        URL: "https://www.facebook.com/p/Sarin-Kotikahvila-100063627640498/",
      },
      {
        text: "Neste",
        URL: "https://www.nestekuhmoinen.fi/kahvila-ja-ravintola/",
      },
      {
        text: "Kelossi (Kellosalmi)",
        URL: "https://www.karabi.fi/kelossi-palvelut",
      },
    ],
    Muut: [
      { text: "Kuhmoisten kunta ", URL: "https://www.kuhmoinen.fi/" },
      {
        text: "Tietokota Kuhmoinen",
        URL: "https://www.facebook.com/groups/730146643698138",
      },
    ],
  };

  return (
    <>
      <Container>
        <Stack className="bg-primary text-primary" gap={1}>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <div className="text-center text-secondary">
                <h2>{process.env.REACT_APP_SITE_TITLE}</h2>
              </div>
            </Col>
            <Col>
              <Button
                className="float-end me-3 m-2"
                // variant="secondary"
                onClick={() => window.location.reload(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
              </Button>
            </Col>
          </Row>
          <Container className="center mx-auto">
            <Temperatures />
          </Container>
          <Container className="mx-auto">
            {Object.keys(links).map((category) => (
              //console.log(category)
              <Category
                key={category}
                categoryName={category}
                category={links[category]}
              />
            ))}
          </Container>
        </Stack>
      </Container>
    </>
  );
};

export default Contents;

import React from "react";
import PropTypes from "prop-types";
import Stack from "react-bootstrap/Stack";
import LinkItem from "./Links";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/esm/Container";

const Category = (props) => {
  //console.log(props.category);

  return (
    <Stack gap={1}>
      <Card className="text-center bg-secondary">
        <Card.Title>
          <h3>{props.categoryName}</h3>
        </Card.Title>
        <Card.Body>
          {Object.values(props.category).map((item) => (
            //console.log(props.links[category])
            //<a href={item.URL}>{item.text}</a>
            <LinkItem key={item.text} link={item} />
          ))}
        </Card.Body>
      </Card>
      <Container></Container>
    </Stack>
  );
};

Category.propTypes = {
  category: PropTypes.array,
  categoryName: PropTypes.string,
};

export default Category;

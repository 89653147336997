import React from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';


const Links = (props) => {
  //console.log(props);

  return (
    <>
      <Button variant="primary" className="m-2 "
        href={props.link.URL}
      >
        {props.link.text}
      </Button>
      {' '}
    </>
  );
};

Links.propTypes = {
  link: PropTypes.object,
};

export default Links;
